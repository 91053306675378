export default {
  Message: {
    Confirm: '確認',
    Cancel: '關閉',
    Title: '信息',
    msg401: '權限不足！',
    msg400: '系統異常！',
    msg404: '請求接口不存在！',
    msg500: '服務器錯誤！',
    msg502: '网络错误！',
    msg503: '服務不可用，服務器暫時過載或維護。',
    LearnMore: '了解更多',
    AWARDS: '獎項',
    BANKINFO: '付款方式',
    QuoteNow: '立即免費獲得報價',
    TESTIMONIAL: '客戶好評',
    VIEWMORE: '查看更多',
    RealCase: '真系統 • 真個案'
  },
  Home: {
    ViewMore: '查看更多',
    NoContent: '暫無內容',
    OurServices: '我們的服務',
    ExpandDetails: '展開詳情',
    EetractDetails: '收起详情',
    ImmediatelyTips: 'Whatsapp 或致電 51002088',
    FCQ: '免費咨詢報價',
    Episodes: '集數',
    LearnMore: '了解更多>>',
    Shrink: '收縮',
    Expand: '展開'
  },
  DeliveryAddress: {
    Address: '地址'
  },
  Order: {
    OrderNumber: '訂單編號',
    OrderDateTime: '訂單日期&時間 ',
    TotalPrice: '總價',
    OrderStatus: '訂單狀態',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上傳文件(jpg, png, pdf)',
    UploadingText: '正在上傳',
    ConfirmText: '確定',
    UploadSuccessfulText: '上傳成功',
    UploadFailText: '上傳失敗',
    NoFileText: '沒有選中文件',
    UploadLengthText: '最多只能上載{0}檔案',
    UploadSizeText: '最多只能上傳{0}M',
    PleaseSelect: '請選擇',
    SubRecord: '提交紀錄',
    ReferenceNum: '參考編號',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '請選擇付款方式',
    PreviewTitleText: '請再次確認頁面',
    FormatErrorText: '請輸入正確電郵地址',
    RequiredText: '請檢查你的答案'
  },
  Display: {
    SuccessCase: '成功個案',
    GeneralQuery: '壹般查詢',
    OfficeHours: '辦公時間',
    Hour0: '星期壹至五：',
    Hour1: '早上9時至下午12時,',
    Hour2: '下午1時30分至晚上6時',
    Hour3: '星期六、日及公眾期假休息',
    AddrInfo: '長沙灣荔枝角道 777號田氏企業中心 3樓 306室 (長沙灣港鐵站 B出口)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即報名',
    ReturnHomePage: '返回首頁',
    OrderDetails: '訂單詳情',
    Facebook: 'Facebook',
    IG: 'IG',
    Youtube: 'Youtube',
    Wechat: 'Wechat',
    TikTok: 'TikTok',
    bilibili: 'bilibili',
    RED: 'RED',
    Baidu: 'Baidu',
    Snapchat: 'Snapchat',
    Tmall: 'Tmall',
    Eventizer: '易玩太爽',
    Backtotop: '回到頂部',
    WhatsApp: 'WhatsApp',
    RegnPay: '填缴易',
    SearchContent: '搜索內容',
    Search: '搜索',
    Stop: '停止',
    Play: '播放',
    TvpTips: '想申請科技券 (TVP)?立即聯絡Eventizer政府資助顧問服務',
    Tvp: 'TVP科技券計劃',
    BudTips: '想申請專項基金(BUD)?立即聯絡Eventizer政府資助顧問服務。'
  },
  Cms: {
    PlsChoose: '請點擊選擇您想要查看的內容：',
    SignUp: '經 Eventizer 成功註冊PayPal商業帳戶',
    Reward: '賺取獎賞的方法如下：',
    First: '透過以下連結「立即免費登記PayPal」，進入註冊PayPal香港商業帳戶頁面',
    Second: '按「立即註冊」，選擇「商業帳戶」種類，遵循指示設定你的帳戶。',
    Last: '完成後，於你註冊之日起計首 60 天內，只要你的首批客戶成功透過 PayPal 完成一筆或多筆交易（總金額滿 HK$600），你將可賺取 HK$300。',
    Last2: '優惠受有關條款及細則約束，詳情請瀏覽',
    Last3: '。',
    PayPalHK: 'PayPal HK',
    Register: '立即免費登記PayPal',
  },
  payment: {
    PaymentSuccessTips: '支付結果： 成功',
    PaymentFailTips: '支付結果： 失敗'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '時間',
    Venue: '地點',
    NoEvent: '今日暫無活動'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一級'
  },
  product: {
    ProductCode: '產品代碼',
    buy: '立即購買',
    addToCart: '加入購物車',
    buyNow: '立即購買',
    favorite: '加入我的清單',
    priceTitle: '價格',
    countTitle: '數量',
    youWouldLike: '你可能喜歡',
    moreTips: '點擊加載更多',
    none: '沒有了',
    noneInCat: '當前目錄無產品，請選擇其他目錄',
    notifyMe: '到貨通知',
    purchasing: '采購中',
    comments: { title: '商品評論' },
    detail: '產品詳情',
    overView: '產品簡介',
    specification: '使用説明',
    attr: { 0: '尺寸', 1: '顔色' },
    SaleOut: '售罄',
    offSale: '已下架',
    Recommend: '會員推薦',
    Remarks: '備注',
    EnterRemaks: '請填寫備注',
    up: '上一個',
    down: '下一個',
    logouted: '您已退出登錄！',
    loginow: '現在重新登陸？',
    successInAdding: '添加成功',
    successInRemoving: '取消成功',
    ProductIntro: '產品介紹',
    ProductIntroduction: '產品介紹',
    UserReviews: '用戶評論',
    OwnerReply: '商家回復',
    per: '第',
    page: '頁',
    Screening: '篩選',
    Resetall: '重置所有篩選條件',
    PriceHL: '價格從高至低',
    PriceLH: '價格從低至高',
    Producttitle: '產品列表',
    Paixu: '排序',
    Total: '共',
    Product: '個產品',
    ProductDetail: '產品詳情',
    Unit: '單位'
  },
  CheckOut: {
    Ordersummary: '訂單摘要',
    Input: '請輸入',
    expiryDate: '過期日期',
    awsl: 'awsl',
    expressError: '快遞異常！請選擇其他快遞。',
    confirmAddress: '請先確認送貨地址',
    pay: '支付',
    CheckOutTitle: '確認訂單',
    Merchant: '商家',
    Product: '產品',
    Qty: '數量',
    Price: '價錢',
    SplitDelivery: '拆單',
    ShippingMethod: '送貨方式',
    PleaseSelect: '請選擇',
    Selected: '已選擇',
    Name: '名字',
    Phone: '電話',
    Address: '地址',
    Pickup: '自取',
    Change: '新增或更改地址',
    DeliveryCharge: '運輸費用',
    SubTotal: '商品小計',
    PayBy: '支付方式',
    Couponcode: '優惠碼',
    confirm: '確認',
    Total: '共',
    BacktoShoppingCart: '返回購物車',
    Last: '上一頁',
    Checkout: '確認訂單',
    Next: '下一頁',
    Delivery: '配送方式與運費',
    SelectDeliveryAddress: '選擇送貨地址',
    LastName: '姓氏',
    Edit: '更改地址',
    Delete: '刪除地址',
    Select: '選取',
    NewAddress: '添加新的收貨地址',
    PickUp: '自取',
    FirstName: '名字',
    ZipCode: '郵政編碼',
    Mobile: '手機',
    Country: '地區',
    Province: '地區/省份',
    City: '區份',
    Close: '關閉',
    PickupDate: '自取日期',
    PickupTime: '自取時間',
    Morning: '早上',
    Afternoon: '下午',
    PickAddressError: '自提信息必須完整',
    AddressLock: '地址已被選中不可刪除',
    CompanyName: '門店',
    CompanyPhone: '門店電話',
    CompanyAddress: '門店地址',
    PickupAddress: '自取地址',
    Save: '保存',
    PleaseChoose: '請選擇',
    PleaseSelectdate: '請選擇日期',
    PleaseSelecttime: '請選擇時間',
    ConfirmationOfOrder: '確認訂單',
    Concessions: '優惠信息',
    PaymentMethod: '支付方式',
    Complete: '完成',
    OrderPreview: '訂單預覽',
    CandP: '優惠券與促銷碼不能同時使用',
    promotionCodeError: '請輸入正確的促銷碼',
    Use: '使用',
    As: '作爲',
    PaymentMethodError: '支付方式尚未选择！',
    OrderSent: '訂單已發出',
    PaymentProcess: '請繼續完成付款程序',

    CountryName: '國家名稱',
    ProvinceName: '省份名稱',
    CityName: '城市名稱',
    SFPointType: '自提點類型'
  },
  paypalText: {
    TipsA: 'Eventizer旗下網上系統現已銜接PayPal',
    TipsB: '深受全球買家信賴',
    TipsC: '客戶享受保障',
    TipsD: '客戶滿意度高',
    TipsE: '多種收款方案支持您的B2C生意',
    TipsF: '全球4億PayPal活躍用戶都是您的潛在客戶'
  }
};
